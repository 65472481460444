<template lang="html">
  <v-container fluid pa-0>
    <v-row>
      <v-col cols="12"><v-patient-bar :value="hn" @patientLoaded="onPatientLoaded" @patientUnloaded="onPatientUnloaded"></v-patient-bar></v-col>
    </v-row>
    <v-row v-if="currentPatient" dense>
      <v-col cols="12">
        <v-covid19-vaccine-bar :hn="currentPatient.hn">
          <v-btn color="primary" @click="printPassport">พิมพ์ Passport</v-btn>
        </v-covid19-vaccine-bar>
      </v-col>
      <v-col cols="12">
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab>เอกสารใหม่</v-tab>
            <v-tab>ประวัติ</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item>
              <v-document-editor :hn="currentPatient.hn" templateCode="cv19-vaccine-discharge" :findLatest="findLatestObject" approveOnSave></v-document-editor>
            </v-tab-item>
            <v-tab-item>
              <v-document-viewer :documentQuery="{hn: currentPatient.hn}"></v-document-viewer>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import patientLoader from '@/components/mixins/patientLoader'
import printDocument from '@/components/mixins/printDocument'
import helperDateTime from 'tantee-common/lib/helperDateTime'
import api from 'tantee-common/lib/api'

export default {
  mixins: [patientLoader,printDocument],
  methods: {
    onPatientLoaded(patient) {
      this.currentPatient = patient
      this.$emit('patientLoaded', patient)
    },
    onPatientUnloaded() {
      this.reset()
    },
    printPassport() {
      if (this.currentPatient) {
        api.post(['Covid19','VaccineController','generateVaccinePassport'],{hn: this.currentPatient.hn}).then((result)=>{
          if (result.success) {
            this.printDocument(result.data.id)
          }
        }).catch((e)=>{
          void e
        })
      }
    }
  },
  computed: {
    findLatestObject() {
      return {
        'whereDate#created_at': helperDateTime.now().format('YYYY-MM-DD')
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
